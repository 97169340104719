<template>
  <div
    v-if="quickShipText"
    class="quick-ship-label"
    :class="{
      'quick-ship-label__mini': size === 'mini',
      'quick-ship-label__small': size === 'small',
      'show-arrow': showArrow
    }"
  >
    <div
      v-if="showArrow"
      class="quick-ship-label__arrow"
    ></div>
    <sui_icon_qucikship_flat_24px
      size="12px"
      color="#198055"
      class="quick-ship-label__icon"
    />
    <span class="quick-ship-label__text">
      {{ quickShipText }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { sui_icon_qucikship_flat_24px } from '@shein-aidc/icon-vue2'
import { formatQuickShipLabel, formatBrQuickShipLabel } from 'public/src/pages/user/child_pages/orders/common/utils'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  size: {
    type: String,
    default: 'small'
  },
  customText: {
    type: String,
    default: ''
  },
  showArrow: {
    type: Boolean,
    default: false
  },
  mallInfo: {
    type: Object,
    default: () => {}
  },
  abtInfo: {
    type: Object,
    default: () => {}
  },
  localData: {
    type: Object,
    default: () => {}
  }, 
  isBrazil: {
    type: Boolean,
    default: false
  },
})

const quickShipText = computed(() => {
  if (props.isBrazil) {
    // 格式化巴西N日达
    return formatBrQuickShipLabel({
      language: props.language, 
      quickShipAbt: props.abtInfo, 
      mallInfo: props.mallInfo, 
      localData: props.localData, 
    })
  }
  return props.customText ? props.customText : formatQuickShipLabel({
    language: props.language, 
    quickShipAbt: props.abtInfo, 
    mallInfo: props.mallInfo, 
    localData: props.localData,
  })
})
</script>

<style lang="less" scoped>
.quick-ship-label {
  display: inline-block;
  position: relative;
  border-radius: 4/75rem;
  line-height: normal;
  text-shadow: none;
  letter-spacing: normal;
  border: 1px solid rgba(25, 128, 85, 0.2);
  background: #ecfcf3;

  &.show-arrow::before {
    content: '';
    display: block;
    position: absolute;
    left: 14px;
    bottom: -4px;
    width: 6px;
    height: 6px;
    transform: skewY(-45deg);
    border-left: 1px solid rgba(25, 128, 85, 0.2);
    border-bottom: 1px solid rgba(25, 128, 85, 0.2);
    background: #ecfcf3;
  }

  &__mini {
    min-height: 12px;
    padding: 0 3px;
    font-size: 8px;
  }

  &__small {
    min-height: 18px;
    padding: 1px 3px;
    font-size: 10px;
  }

  &__icon {
    margin-right: 4/75rem;
  }

  &__text {
    font-style: italic;
    font-weight: 500;
    color: #198055;
  }
}
</style>
