<template>
  <div class="show-aging-text">
    <!-- 运输方式 -->
    <div
      v-if="getShippingMethod && [3, 4].includes(type) && !isUnPaiMoreAddressOrder"
      class="aging-text transport-type"
    >
      <p
        class="title"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_15371 }}:
      </p>
      <p
        class="text"
        tabindex="0"
      >
        {{ getShippingMethod }}
      </p>
    </div>
    
    <p
      v-if=" (timeEffectsList.includes(1)|| timeEffectsList.includes(4)|| timeEffectsList.includes(2) || timeEffectsList.includes(3)) && getStoreTransportTimeTitle "
      class="title"
      tabindex="0"
    >
      {{ getStoreTransportTimeTitle }}
    </p>
    <text-ellipsis :isNeedShrink="true">
      <template #default="{ className }">
        <div
          class="time-large__text"
          :class="className"
        >
          <!-- 普通运输时效 -->
          <template v-if="(timeEffectsList.includes(1)|| timeEffectsList.includes(4)) && getStoreTransportTimeTitle">
            <div
              class="aging-text logistics-time"
            >
              <span
                class="text"
                tabindex="0"
                v-html="getStoreTransportTimeText"
              >
              </span>

              <span
                v-if="showOrderDayPercentDes"
                class="percent-time new-percent__time"
              >
                <span v-html="getOrderDayPercentInfo.day_percent_desc"></span>
                <sui_icon_doubt_16px_2
                  v-if="getOrderDayPercentInfo.transport_time_detail_list && !!getOrderDayPercentInfo.transport_time_detail_list.length"
                  size="16px"
                  :is-rotate="locals.GB_cssRight"
                  @click.native.stop="showDayPercentList({ method: getShippingMethod, percentList: getOrderDayPercentInfo.transport_time_detail_list, percentDes: getOrderDayPercentInfo.day_percent_desc })"
                />
              </span>
            </div>
          </template>  
          <!-- qs文案 -->
          <div
            v-if="(timeEffectsList.includes(2) || timeEffectsList.includes(4)) && getStoreTransportTimeTitle && getStoreQuickShipTimeText"
            class="aging-text logistics-time"
          >
            <p
              v-expose="{ id: '1-19-1-86', data: { quickship_tp: timeEffectsList.includes(4)? 2 : 1 } }"
              class="text"
              tabindex="0"
              v-html="getStoreQuickShipTimeText"
            >
            </p>
          </div>

          <!-- 海陆运时效 -->
          <template v-if="timeEffectsList.includes(3) && getStoreTransportTimeTitle">
            <div
              class="aging-text logistics-time"
            >
              <span
                class="text"
                tabindex="0"
                v-html="transportLargeSeaAndLandText"
              >
              </span>
              <span
                v-if="showOrderDayLargePercentDes"
                class="percent-time new-percent__time"
              >
                <span v-html="getOrderDayLargePercentInfo.day_percent_desc"></span>
                <sui_icon_doubt_16px_2
                  v-if="getOrderDayLargePercentInfo.time_detail_list && !!getOrderDayLargePercentInfo.time_detail_list.length"
                  size="16px"
                  :is-rotate="locals.GB_cssRight"
                  @click.native.stop="showDayPercentList({ method: getShippingMethod, percentList: getOrderDayLargePercentInfo.time_detail_list, percentDes: getOrderDayLargePercentInfo.day_percent_desc })"
                />
              </span>
            </div>
          </template>
        </div>
      </template>
    </text-ellipsis>
    <div
      v-if="[3, 4].includes(type) && getStoreExchangeTime"
      class="aging-text exchange-time"
    >
      <p
        class="title"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_18133 }}:
      </p>
      <text-ellipsis>
        <template #default="{ className }">
          <p
            class="time-text"
            :class="className"
            tabindex="0"
            v-html="getStoreExchangeTime"
          >
          </p>
        </template>
      </text-ellipsis>
      <p
        v-if="showExchangePercentDes"
        class="percent-time"
      >
        <span v-html="getExchangeDayPercentInfo.day_percent_desc"></span>
        <sui_icon_doubt_16px_2
          v-if="getExchangeDayPercentInfo.transport_time_detail_list && !!getExchangeDayPercentInfo.transport_time_detail_list.length"
          size="16px"
          :is-rotate="locals.GB_cssRight"
          @click.stop="showDayPercentList({ method: getShippingMethod, percentList: getExchangeDayPercentInfo.transport_time_detail_list, percentDes: getExchangeDayPercentInfo.day_percent_desc })"
        />
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import orderLogic from 'public/src/pages/common/orderLogic'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { transformPercentList } from 'public/src/pages/user/child_pages/orders/utils.js'
import { formatDoubleTime, formatTime, formatQuickShipDesc } from 'public/src/pages/user/child_pages/orders/common/utils'
import TextEllipsis from './TextEllipsis.vue'
import { uniq } from 'lodash'
import { sui_icon_doubt_16px_2 } from '@shein-aidc/icon-vue2'

daEventCenter.addSubscriber({ modulecode: '1-19-1' })

export default {
  name: 'TransportTime',
  components: {
    TextEllipsis,
    sui_icon_doubt_16px_2
  },
  directives: {
    expose
  },
  props: {
    type: {
      type: Number,
      default: 1
    },
    language: {
      type: Object,
      default: () => {}
    },
    mallOrStoreObj: {
      type: Object,
      default: () => {}
    },
    orderInfo: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('orderDetail', ['isBrazil']),
    ...mapState('orderDetail', [
      'orderResultAbtInfo',
      'apolloInfo',
      'locals'
    ]),
    // *获取当前mall下所有商品
    currentGoodList() {
      const goodList = orderLogic.getAllOrderGoodsList(this.orderInfo)
      const mall_code = this.mallOrStoreObj?.mall_code || ''
      const store_code = this.mallOrStoreObj?.store_code || ''
      if(mall_code){
        return goodList?.filter(item => item?.mall_code == mall_code) || []
      }
      if(store_code){
        return goodList?.filter(item => item?.store_code == store_code) || []
      }
      return []   
    },
    // *判断展示什么时效
    timeEffectsList(){
      const goodList = this.currentGoodList || []
      let list = []
      goodList.forEach(item => {
        if((item?.quick_ship == 1 || item?.quick_ship == 2)  && (item?.large_sea_and_land == 1 || item?.large_sea_and_land == 2)){
          list.push(2)
        }else if(item?.quick_ship == 0 && item?.large_sea_and_land == 0){
          list.push(1)
        }else if(item?.quick_ship == 1){
          if(this.isMultiMall){
            list.push(1)
          }else {
            list.push(2)
          } 
        } else if(item?.large_sea_and_land == 1 || item?.large_sea_and_land == 2){
          list.push(3)
        } else if(item?.quick_ship == 2){
          if(this.isMultiMall){
            list.push(1)
          }else {
            list.push(4)
          }     
        }
      })
      return uniq(list) 
    },
    // *获取当前mall的时效内容
    currentTimeList(){
      const mall_code = this.mallOrStoreObj?.mall_code || ''
      const store_code = this.mallOrStoreObj?.store_code || ''
      if(mall_code){
        const handle = this.orderInfo?.mall_list?.find(item => item.mall_code == mall_code)
        const shippedList = handle?.shipped_transport_time_list?.length ? handle?.shipped_transport_time_list : null
        return shippedList || handle?.transport_time_list || []
      }
      if(store_code) {
        const handle = this.orderInfo?.mall_list?.find(item => item.store_code == store_code)
        const shippedList = handle?.shipped_transport_time_list?.length ? handle?.shipped_transport_time_list : null
        return shippedList || handle?.transport_time_list || []
      }
      return []
    },
    // *是否为多mall
    isMultiMall() {
      let { is_multi_mall } = this.orderInfo || {}
      if(is_multi_mall == 1){
        return true
      }
      if (this.type != 2) {
        return true
      }
      return false
    },
    // *海运还是陆运
    seaOrLandTypeText() {
      return  orderLogic?.seaOrLandTypeText(this.orderInfo, this.language)
    },
    // *海陆运时效文案
    transportLargeSeaAndLandText() {
      const mallItem = this.currentTimeList?.find(item => item?.type == 2) || {}
      const { transport_time = '', origin_delivery_desc = '', origin_shipping_desc = '', transport_time_type, transport_date_type, sub_transport_time, title = '' } = mallItem
      let titleText = title ? title : this.seaOrLandTypeText
      let transportTime = transport_time || ''
      if (!transportTime) return ''
 
      const originDeliveryDesc = origin_delivery_desc || ''
      const originShippingDesc = origin_shipping_desc || ''


      const subTransportTime = sub_transport_time || ''  
      let text = ''
      //满足双时效展示条件
      if (
        this.showDoubleShippingTime &&
        !this.isUnPaidOrder &&
        originDeliveryDesc &&
        originShippingDesc &&
        subTransportTime
      ) {
        text = formatDoubleTime({
          originDeliveryDesc,
          originShippingDesc,
          subTransportTime,
          transportTime,
          transportTimeType: transport_time_type,
          useEnum: true,
          localData: this.locals
        })
      } else if (transport_time_type == 2) {
        const formatDate = formatTime(transportTime, this.locals)
        if (this.isUnPaidOrder || !originDeliveryDesc) {
          if(this.isUnPaidOrder){
            text = `${this.seaOrLandTypeText}: ${this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)}`
          }else {
            text = `${titleText}: ${this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)}`
          }
        } else {
          text = originDeliveryDesc?.replace('%s', formatDate)
        }
      } else if (transport_time_type == 1) {
        const isShow = !originShippingDesc  || this.isUnPaidOrder
        if(transport_date_type == 0) {
          if(isShow){
            if(this.isUnPaidOrder){
              text = `${this.seaOrLandTypeText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_28881)}`
            }else{
              text = `${titleText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_28881)}`
            }
          }else{
            text = originShippingDesc?.replace('%s', transportTime)
          }
        }
        if(transport_date_type == 1){
          if(isShow){
            if(this.isUnPaidOrder){
              text = `${this.seaOrLandTypeText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_24764)}`
            }else {
              text = `${titleText}: ${this.template(transportTime, this.language.SHEIN_KEY_PWA_24764)}`
            }
          }else{
            text = originShippingDesc?.replace('%s', transportTime)
          }
        }
      }    
      return text
    },
    // *海陆运时效百分比对象
    getOrderDayLargePercentInfo(){
      const {
        delivery_day_percent_desc = '',
        time_detail_list = []
      } = this.currentTimeList?.find(item => item?.type == 2) || {}
      let day_percent_desc = ''
      if (delivery_day_percent_desc) {
        day_percent_desc = delivery_day_percent_desc
      } 

      return { day_percent_desc, time_detail_list }
    },

    timePercentAbt () {
      return !!this.orderResultAbtInfo?.timePercentAbt
    },
    // *是否展示海路运时效百分比
    showOrderDayLargePercentDes() {
      return this.timePercentAbt && this.getOrderDayLargePercentInfo?.day_percent_desc
    },
    // 双时效开关
    showDoubleShippingTime() {
      return !!this.orderResultAbtInfo?.showDoubleShippingTime
    },
    isUnPaidOrder () {
      return [0, 12, 13].includes(+this.orderInfo?.orderStatus)
    },
    // * 未支付多地址场景
    isUnPaiMoreAddressOrder(){
      return this.isUnPaidOrder && this.orderInfo?.address_list?.length > 1
    },
    quickShipAbt() {
      return this.orderResultAbtInfo?.quickShipAbt || {}
    },
    getShippingInfo() {
      let { store_code = '', mall_code = '' } = this.mallOrStoreObj || {}
      let { mall_list = [] } = this.orderInfo || {}
      let flag = this.type
      let defaultObj = {}
      mall_list = mall_list?.filter(mallOrStore => mallOrStore?.store_code != '0')
      if (flag == 2) {
        defaultObj = mall_list?.find(item => item.store_code == store_code) || {}
      } else {
        defaultObj = mall_list?.find(item => item.mall_code == mall_code) || {}
      }
      return defaultObj
    },
    getShippingMethod () {
      if (this.isUnPaiMoreAddressOrder) {
        const shippingMethodList = (this.orderInfo?.address_list || []).map(item => item?.shipping_method)
        return shippingMethodList.join(',')
      }
      return this.getShippingInfo?.shipping_method || ''
    },
    showExchangePercentDes() {
      return this.timePercentAbt && this.getExchangeDayPercentInfo?.day_percent_desc
    },
    getExchangeDayPercentInfo() {
      let exchange_shipping_time = this.getShippingInfo?.exchange_shipping_time || {}

      const { delivery_day_percent_desc = '', percent = '', days = '', day_percents = [] } = exchange_shipping_time
      let day_percent_desc = ''
      if (delivery_day_percent_desc) {
        day_percent_desc = delivery_day_percent_desc
      } else if (percent && days) {
        day_percent_desc = this.template(percent, days, this.language.SHEIN_KEY_PWA_23403)
      }

      return { day_percent_desc, transport_time_detail_list: day_percents }
    },
    getStoreExchangeTime () {
      let exchange_shipping_time = this.getShippingInfo?.exchange_shipping_time || {}
      if (!exchange_shipping_time?.transport_time) return ''
      const originDeliveryDesc = exchange_shipping_time.origin_delivery_desc || ''
      const originShippingDesc = exchange_shipping_time.origin_shipping_desc || ''
      const subTransportTime = exchange_shipping_time.sub_transport_time || ''
      //满足双时效展示条件
      if (
        this.showDoubleShippingTime &&
        !this.isUnPaidOrder &&
        originDeliveryDesc &&
        originShippingDesc &&
        subTransportTime
      ) {
        return formatDoubleTime({
          originDeliveryDesc,
          originShippingDesc,
          subTransportTime,
          transportTime: exchange_shipping_time.transport_time,
          transportTimeType: exchange_shipping_time.desc_type,
          localData: this.locals
        })
      } else if (exchange_shipping_time.desc_type == 1) {
        return `${this.language.SHEIN_KEY_PWA_15627} ${formatTime(exchange_shipping_time.transport_time, this.locals)}`
      } else  {
        const isShow = (!originShippingDesc && !originDeliveryDesc) || this.isUnPaidOrder
        if (isShow && exchange_shipping_time.date_type == 0) {
          return this.template(exchange_shipping_time.transport_time, this.language.SHEIN_KEY_PWA_28881)
        } else {
          return this.template(exchange_shipping_time.transport_time, this.language.SHEIN_KEY_PWA_17357)
        }
      }
    },
    showOrderDayPercentDes() {
      return this.timePercentAbt && this.getOrderDayPercentInfo?.day_percent_desc
    },
    getOrderDayPercentInfo() {
      const { delivery_day_percent_desc = '', transport_time_detail_list = [] } = this.getShippingInfo || {}
      let day_percent_desc = ''
      if (delivery_day_percent_desc) {
        day_percent_desc = delivery_day_percent_desc
      } 

      return { day_percent_desc, transport_time_detail_list }
    },
    getStoreTransportTime () {
      let transportTimeObj = { ...this.getShippingInfo, desc_type: this.getShippingInfo?.transport_time_type }
      return transportTimeObj || {}
    },
    getStoreTransportTimeTitle () {
      // let transportTimeObj = this.getStoreTransportTime || {}
      // if (!transportTimeObj?.transport_time) return ''
      // return transportTimeObj?.desc_type == 1 ? this.language.SHEIN_KEY_PWA_17513 : this.language.SHEIN_KEY_PWA_17359
      return  this.language.SHEIN_KEY_PWA_17359
    },
    getStoreTransportTimeText () {
      let transportTimeObj = this.getStoreTransportTime || {}
      if (!transportTimeObj?.transport_time) return ''

      const originDeliveryDesc = transportTimeObj?.origin_delivery_desc || ''
      const originShippingDesc = transportTimeObj?.origin_shipping_desc || ''
      const subTransportTime = transportTimeObj?.sub_transport_time || ''
      let text = ''
      //满足双时效展示条件
      if (
        this.showDoubleShippingTime &&
        !this.isUnPaidOrder &&
        originDeliveryDesc &&
        originShippingDesc &&
        subTransportTime
      ) {
        text = formatDoubleTime({
          originDeliveryDesc,
          originShippingDesc,
          subTransportTime,
          transportTime: transportTimeObj.transport_time,
          transportTimeType: transportTimeObj.transport_time_type,
          localData: this.locals
        })
      } else if (transportTimeObj.desc_type == 1) {
        const formatDate = formatTime(transportTimeObj.transport_time, this.locals)
        if (this.isUnPaidOrder) {
          text = this.template(formatDate, this.language.SHEIN_KEY_PWA_26651)
        } else {
          const originDesc = `${this.language.SHEIN_KEY_PWA_15627} ${formatDate}`
          text = originDeliveryDesc ? originDeliveryDesc?.replace('%s', formatDate) : originDesc
        }
      } else if (transportTimeObj.desc_type == 0) {
        const isShow = (!originShippingDesc && !originDeliveryDesc) || this.isUnPaidOrder
        if (isShow && transportTimeObj.date_type == 0) {
          text = this.template(transportTimeObj.transport_time, this.language.SHEIN_KEY_PWA_28881)
        } else if (this.isUnPaidOrder && transportTimeObj.date_type == 1) {
          text = this.template(transportTimeObj.transport_time, this.language.SHEIN_KEY_PWA_24764)
        } else if (originShippingDesc) {
          text = originShippingDesc.replace('%s', transportTimeObj.transport_time)
        } else {
          text = this.template(transportTimeObj.transport_time, this.language.SHEIN_KEY_PWA_24764)
        }
      }

      if (this.supplyTransportTimeText) {
        text = `${text} ${this.supplyTransportTimeText}`
      }
      return text
    },
    // 获取当前商品的mall信息
    goodsMallInfo() {
      const mallList = this.orderInfo?.mall_list || []
      const goodsList = this.currentGoodList || []
      const item = goodsList[0] || {}
      const currentMall = mallList.find(mall => {
        return mall.mall_code == item.mall_code && mall.store_code == item.store_code
      })
      return currentMall || {}
    },
    getStoreQuickShipTimeText () {
      let transportTimeObj = this.getStoreTransportTime || {}
      let quickShipDesc = transportTimeObj?.quick_ship_desc || ''

      // 物流轨迹“时效类型”=2，格式化时间戳
      if (transportTimeObj?.quick_ship_time_type == 2 && transportTimeObj?.quick_ship_time && transportTimeObj?.origin_desc_quick_ship_time) {
        const timeRange = transportTimeObj.quick_ship_time.split('-').map(Number)
        const timeStr = timeRange.map(time => this.handlerDate(time)).join('-')
        quickShipDesc = transportTimeObj.origin_desc_quick_ship_time.replace('%s', timeStr)
      }
      if (this.getQuickShipTag == 2 && this.supplyTransportTimeText) {
        quickShipDesc = `${quickShipDesc} ${this.supplyTransportTimeText}`
      }
      return formatQuickShipDesc({
        quickShipDesc: quickShipDesc, 
        language: this.language, 
        quickShipAbt: this.quickShipAbt, 
        mallInfo: this.goodsMallInfo,
        localData: this.locals,
        isBrazil: this.isBrazil
      })
    },
    getQuickShipTag () {
      let { is_multi_mall } = this.orderInfo || {}
      let { newGoodsList = [] } = this.mallOrStoreObj || {}
      // 非单mall
      if (is_multi_mall == 1) return 0
      // 非单mall多店铺
      if (this.type != 2) return 0
      return orderLogic?.getQuickShipTransportTimeType(newGoodsList)
    },
    supplyTransportTimeText() {
      // 大件运输时效文案
      let transportTimeObj = this.getStoreTransportTime || {}
      return transportTimeObj?.supple_desc || ''
    },
  },
  methods: {
    ...mapMutations('orderDetail', ['assignState']),
    template,
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, false, this.locals)
    },
    showDayPercentList({ method, percentList, percentDes }) {
      let list = transformPercentList(percentList)
      this.assignState({ 
        showShippingDayPercent: true,
        dayPercentInfo: { method, percentList: list, percentDes }
      })
      daEventCenter.triggerNotice({
        daId: '1-19-1-89',
        extraData: {
          bill_no: this.orderInfo.billno,
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.percent-time {
  span {
    .font-dpr(24px);
    color: @sui_color_gray_dark1; 
  }
  i {
    color: @sui_color_gray_light1;
  }
}
.time-large__text .logistics-time {
  margin-bottom: 0px;
  .text {
    color: @sui_color_gray_dark3;
    font-size: 12px;
    font-weight: 400;
  }
  .new-percent__time {
    color: @sui_color_gray_dark3;
    font-size: 12px;
    font-weight: 400;
    span {
      color: @sui_color_gray_dark3;
      font-size: 12px;
      font-weight: 400;
    }
    i {
      color: @sui_color_gray_dark3;
    }
  }
}
</style>
