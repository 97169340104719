<template>
  <div
    v-show="showTips"
    ref="container"
    class="resume-shipment-alert"
    :style="styleObject"
    @click.stop
  >
    <div class="resume-shipment-alert__content">
      <div
        class="resume-shipment-alert__text"
        v-html="language.SHEIN_KEY_PWA_34359"
      ></div>
    </div>

    <Icon
      v-if="showClose"
      class="resume-shipment-alert__close"
      name="sui_icon_close_16px"
      size="10px"
      @click.stop="onClose"
    />
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-19-1' })

export default {
  name: 'ResumeShipmentAlert',
  components: {
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    showClose: {
      type: Boolean,
      default: true
    },
    locals: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showTips: true,
      maxWidth: 250,
    }
  },
  computed: {
    styleObject() {
      return {
        maxWidth: `${this.maxWidth}px`
      }
    },
  },
  mounted() {
    this.getContainPosition()
  },
  methods: {
    onClose() {
      this.showTips = false
      daEventCenter.triggerNotice({
        daId: '1-19-1-154',
        extraData: {}
      })
    },
    getContainPosition() {
      const curDom = this.$refs.container
      if (curDom && curDom.parentNode) {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
        const scale = rootFontSize / 37.5 || 1
        const basicWidth = scale * 250 // 设计稿的默认宽度
        const parentRect = curDom.parentNode.getBoundingClientRect()
        const gutterSize = 4 // 预留和边界的间隔大小
        if (this.locals.GB_cssRight) {
          const maxWidth = window.innerWidth - parentRect.left - gutterSize
          this.maxWidth = Math.min(maxWidth, basicWidth) 
        } else {
          const maxWidth = parentRect.right - gutterSize
          this.maxWidth = Math.min(maxWidth, basicWidth) 
        }

        setTimeout(() => {
          this.showTips = false
        }, 3000) // 3秒后隐藏
      }
    },
  }
}
</script>
<style lang="less" scoped>
.resume-shipment-alert {
  position: absolute;
  top: -16/75rem;
  right: 0;
  z-index: 5;
  transform: translateY(-100%);  
  display: flex;
  max-width: 480/75rem;
  padding: 16/75rem;
  justify-content: center;
  align-items: center;
  direction: ltr !important;
  border-radius: 2px;
  color: @sui_color_white;
  background: @sui_color_black_alpha80;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 16/75rem;
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid @sui_color_black_alpha80; 
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
  }

  &__icon {
    position: relative;
    width: 20/75rem;
    height: 20/75rem;
    z-index: 11;
  }

  &__content {
    max-width: 480/75rem;
    width: max-content;
    word-break: break-word;
    white-space: normal;
    .line-camp(3);
  }

  &__text {
    position: relative;
    z-index: 11;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    text-transform: none;
  }
}
</style>
