<template>
  <li da-expose-code="orderDetailProductBox">
    <a
      v-expose="{ id: '1-19-1-87', data: { goods_list: getGoodsListStr(product, index) } }"
      v-tap="{ id: '1-19-1-88', data: { goods_list: getGoodsListStr(product, index) } }"
      :class="['list-href', 'ga-order-goods']"
      :data-reason="'1' + product.return_flag"
      @click="clickGoodsList(product)"
    >
      <div
        class="left-inner"
        :style="{ width: '2rem', height: '2.67rem' }"
      >
        <div
          v-if="[1, 2].includes(showFreeGiftTag(product))"
          :class="['free-tag', { 'special-free__tag': showFreeGiftTag(product) == 2 }]"
        >
          <p :class="{ 'small-font': smallFont }">
            {{ language.SHEIN_KEY_PWA_25420 }}
          </p>
        </div>
        <CropImageContainer
          :img-src="transformImg({ img: product.product.goods_img })"
          fixed-ratio="3-4"
          :transformImgProps="{
            isFirstPage: true
          }"
        />
        <template v-if="product.is_exchange">
          <div class="mask_ctn"></div>
          <div class="soldOut-box">
            <i :class="['iconfont', locals.IS_RW ? 'icon-category-' : 'icon-soldout-64px']"></i>
            <div class="text">{{ language.SHEIN_KEY_PWA_16284 }}</div>
          </div>
        </template>
      </div>
      <div :class="['inner', product.is_exchange ? 'exchange-goods' : '']">
        <div
          v-if="chemicalsIds.includes(product.product.goods_sn)"
          class="warning-tip title-inner"
          @click.stop="showP65Warning(product.product.goods_sn)"
        >
          <img :src="`${locals.PUBLIC_CDN}/pwa_dist/images/warning-db80c9224b.png`" />
          <span>{{ language.SHEIN_KEY_PWA_20813 }}</span>
        </div>
        <div class="struct mshe-flexbetween">
          <span class="struct-goods-name">
            {{ blindBoxFlag ? language.SHEIN_KEY_PWA_35001 : product.product.goods_name }}
          </span>
          <span class="struct-price">
            <div>
              <primeVipIcon v-if="product.discount_product_mark" />
              <span
                :class="{
                  'struct-price__dis': isRetailPrice,
                  'struct-price-prime': isRetailPrice && product.discount_product_mark
                }"
              >
                {{ isPriceAmount }}
              </span>
              <sui_icon_doubt_16px_2
                v-if="paymentAmountTips"
                class="price-tips-ctn"
                size="12px"
                :is-rotate="locals.GB_cssRight"
                @click.native.stop="showTip"
              />
              <br />
              <del v-if="!hideRetailPrice && isRetailPrice && !isReallocate">
                {{ replaceAmountWithSymbol(product) }}
              </del>
            </div>
          </span>
        </div>
        <p 
          class="size mshe-flexbetween size-info"
          :class="{ 'flex-end': blindBoxFlag }"
        >
          <skuSizeCommon
            v-if="!blindBoxFlag"
            class="size-inf"
            :goods-info="product"
            :language="language.SHEIN_KEY_PWA_16477"
            :default-local-size-info="productSizeRulesInfo"
            :is-new-special-size="true"
          />
          <em class="size-qty">x{{ product.quantity }}</em>
        </p>
        <!-- 巴西N日达 -->
        <template v-if="isBrazil">
          <QuickShipLabel
            v-if="showBrazilN"
            class="goods-qs-tag"
            :is-brazil="true"
            :language="language"
            :abt-info="quickShipAbt"
            :mall-info="goodsMallInfo"
            :local-data="locals"
          />
        </template>
        <QuickShipLabel
          v-else-if="showQuickShipTag(product) || commonDurationText"
          class="goods-qs-tag"
          :language="language"
          :customText="commonDurationText"
          :abt-info="quickShipAbt"
          :mall-info="goodsMallInfo"
          :local-data="locals"
        />
        <LargeTimeLabel 
          v-if="showSeaOrLandTypeText" 
          :language="language" 
          :text="showSeaOrLandTypeText"
          :isStore="true"
        />
        <s-label 
          v-if="product.product.evolu_flag == 1"
          class="order-evolu"
          type="sellpoint"
        >
          {{ language.SHEIN_KEY_PWA_23114 }}
        </s-label>
        <p
          v-if="showFreeShipTag(product)"
          class="ship-tag"
        >
          {{ language.SHEIN_KEY_PWA_25453 }}
        </p>
        <div
          v-if="product.is_exchange && isXtraOrPrimeFlag && showOpenSelfExchange"
          class="operator_box"
        >
          <div class="exchange_tips">{{ language.SHEIN_KEY_PWA_17522 }}</div>
          <div class="exchange_btn">
            <a
              v-if="isShowContinueWait(product)"
              href="javascript:;"
              @click.stop="continueToWait(product)"
            >
              {{ language.SHEIN_KEY_PWA_35390 }}
            </a>
            <a
              v-if="product.customization_flag != 1"
              href="javascript:;"
              DA-view-type="ordinary"
              DA-sa-name="exchange"
              DA-sa-beacon="1"
              :DA-sa-param="JSON.stringify({ ordergoodsid: product.id, goodsid: product.goods_id })"
              @click.stop="exchangeProductEvt(product)"
            >
              {{ language.SHEIN_KEY_PWA_16553 }}
            </a>
            <a
              href="javascript:;"
              @click.stop="cancelProductEvt(product)"
            >
              {{ language.SHEIN_KEY_PWA_15254 }}
            </a>
          </div>
        </div>
        <!-- 显示商品层级的加车 -->
        <ProductRepurchase 
          v-if="showProductRepurchase && !blindBoxFlag"
          :product="product"
          @handleShowSuccessPanel="handleShowSuccessPanel"
        />
      </div>
    </a>
    <custom-content
      v-if="product.customization_flag == 1"
      :language="language"
      :product="product"
      :customization-info="product.customization_info"
    />
    <p
      v-show="product.double_status == 0"
      class="return-policy-tips extra-point-tip"
      da-event-expose="1-8-5-18"
      :data-skc_code="product.product.goods_sn"
    >
      <sui_icon_caution_12px_2 size="12px" />
      {{ language.SHEIN_KEY_PWA_21240 }}
    </p>
    <template v-if="!product.is_exchange">
      <p
        v-if="withoutReturnTips[product.return_flag]"
        class="return-policy-tips"
      >
        <i class="suiiconfont sui_icon_caution_12px_2"></i> 
        <span v-html="withoutReturnTips[product.return_flag]"></span>
      </p>
    </template>
    <ClientOnly>
      <lazy-mount>
        <s-dialog
          :visible.sync="showAvgPriceTip"
          :close-on-click-modal="false"
          :append-to-body="true"
        >
          <div v-html="paymentAmountTips"></div>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="showAvgPriceTip = false"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 整单退关联商品一起退 -->
      <cancelRelationGoodItem
        :show="showRelationDialog"
        :relationGoodsList="relationGoodsList"
        :relationOptions="relationOptions"
        :language="language"
        @confirmCancelSubmit="confirmCancelSubmit"
        @updateShowValue="updateShowValue"
      />
    </ClientOnly>
  </li>
</template>
<script>
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import schttp from 'public/src/services/schttp'
import { transformImg, replaceHrefSpecChar, template } from '@shein/common-function'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { analysis } from './analysis/ga'
import CustomContent from './components/CustomContent.vue'
import ProductRepurchase from './components/ProductRepurchase.vue'
import skuSizeCommon from 'public/src/pages/common/orderLogic/skuSizeCommon.vue'
import checkoutMixin from 'public/src/pages/checkout/mixins/checkout-mixin.vue'
import primeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'
import QuickShipLabel from '../common/QuickShipLabel.vue'
import LargeTimeLabel from 'public/src/pages/user/child_pages/orders/common/LargeTimeLabel.vue'
import orderLogic from 'public/src/pages/common/orderLogic'
import { daEventCenter } from 'public/src/services/eventCenter'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { defaultP65TipsContent, chemicalsIdsEvt } from 'public/src/pages/checkout/tools/p65util.js'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { REPURCHASE_ORDER_STATUS } from 'public/src/pages/user/child_pages/orders/common/constants.js'
import { Popover, Dialog, Label, LazyMount } from '@shein/sui-mobile'
import { sui_icon_doubt_16px_2, sui_icon_caution_12px_2 } from '@shein-aidc/icon-vue2'
import { showBrNDayDelivery, getCommonDurationText } from 'public/src/pages/user/child_pages/orders/common/utils'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

;[Popover, Dialog, Label].forEach(u => { Vue.use(u) })

daEventCenter.addSubscriber({ modulecode: '1-8-5' })
daEventCenter.addSubscriber({ modulecode: '1-19-1' })

const daEventExpose = daEventCenter.getExposeInstance()
export default {
  name: 'ProductItem',
  components: {
    LazyMount,
    ClientOnly,
    skuSizeCommon,
    primeVipIcon,
    CropImageContainer,
    CustomContent,
    ProductRepurchase,
    QuickShipLabel,
    LargeTimeLabel,
    sui_icon_doubt_16px_2,
    sui_icon_caution_12px_2,
    cancelRelationGoodItem: () => import(/* webpackChunkName: "OrderDetailItem_Comp" */'public/src/pages/user/child_pages/orders/list/cancelRelationGoodItem.vue'),
  },
  directives: {
    expose,
    tap
  },
  mixins: [checkoutMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    product: Object,
    // eslint-disable-next-line vue/require-default-prop
    index: Number,
    chemicalsIds: {
      type: Array,
      default() {
        return []
      }
    },
    isShowQuickShipTag: {
      type: String,
      default: ''
    },
    isXtraOrPrimeFlag: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showAvgPriceTip: false,
      showRelationDialog: false,
      relationGoodsList: [],
      relationOptions: {
        type: '',
        url: '',
        billno: '',
        cancelInfo: {},
        operatorType: 'full'
      },
      chosenContinueWait: false
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'orderInfo', 
      'language', 
      'orderType', 
      'orderResultAbtInfo',
      'apolloInfo',
      'productSizeRulesInfo',
      'productStockList',
      'locals',
    ]),
    ...mapGetters('orderDetail', ['isBrazil']),
    ...mapState('root', ['rootStatus']),
    ...mapState('cancelItemModule', ['refundForm']),
    // 获取当前商品的mall信息
    goodsMallInfo() {
      const item = this.product || {}
      const mallList = this.orderInfo?.mall_list || []
      const currentMall = mallList.find(mall => {
        return mall.mall_code == item.mall_code && mall.store_code == item.store_code
      })
      return currentMall || {}
    },
    showBrazilN() {
      return showBrNDayDelivery({
        abtInfo: this.quickShipAbt,
        mallInfo: this.goodsMallInfo,
        goodsList: [this.product]
      })
    },
    commonDurationText (){
      return getCommonDurationText({
        language: this.language,
        abtInfo: this.quickShipAbt,
        mallInfo: this.goodsMallInfo,
        localData: this.locals
      })
    },
    // 是否有盲盒商品 
    blindBoxFlag() {
      return Boolean(this.product.product.blind_box_flag)
    },
    orderRepurchaseParam() {
      return this.orderResultAbtInfo.orderRepurchaseParam || {}
    },
    // 商品有存库
    hasStockQuantity () {
      const stockItem = this.productStockList.find(child => {
        return child.goods_id == this.product.goods_id
      })
      return stockItem?.on_sale_mall_list?.length >= 1
    },
    // 显示商品层级的加车
    showProductRepurchase() {
      return (
        ![1, 7].includes(+this.orderInfo.marketing_type) &&
        REPURCHASE_ORDER_STATUS.includes(+this.orderInfo.orderStatus) &&
        !this.product.is_prime_goods && // 不是付费会员赠品
        this.product.customization_flag != 1 && // 定制商品不展示
        this.hasStockQuantity //有库存
      )
    },
    // * 是否是德国站点
    isDe(){
      return ['pwde', 'de', 'andshde', 'iosshde'].includes(this.orderInfo?.site_from || '')
    },
    // * 不可退原因
    withoutReturnTips(){
      const policyUrl = `${this.locals.langPath || ''}/Return-Policy-a-281.html`
      if(this.orderResultAbtInfo?.showNewReturnReason){
        const {
          SHEIN_KEY_PWA_33463 = '',
          SHEIN_KEY_PWA_15392 = '', 
          SHEIN_KEY_PWA_33467 = '', 
          SHEIN_KEY_PWA_33464 = '', 
          SHEIN_KEY_PWA_33468 = '', 
          SHEIN_KEY_PWA_33465 = '', 
          SHEIN_KEY_PWA_33466 = '' 
        } = this.language || {}
        return {
          1: this.isDe ? SHEIN_KEY_PWA_33467 : template(policyUrl, SHEIN_KEY_PWA_15392, SHEIN_KEY_PWA_33463) || '',
          2: this.isDe ? SHEIN_KEY_PWA_33467 : template(policyUrl, SHEIN_KEY_PWA_15392, SHEIN_KEY_PWA_33464) || '',
          3: this.isDe ? SHEIN_KEY_PWA_33467 : template(policyUrl, SHEIN_KEY_PWA_15392, SHEIN_KEY_PWA_33464) || '',
          102: SHEIN_KEY_PWA_33468,
          100: SHEIN_KEY_PWA_33465,
          101: SHEIN_KEY_PWA_33466,
        }
      } else {
        const {
          SHEIN_KEY_PWA_15391 = '',
          SHEIN_KEY_PWA_15392 = '', 
          SHEIN_KEY_PWA_15856 = '', 
          SHEIN_KEY_PWA_16113 = '', 
        } = this.language || {}
        
        return {
          1: `${ SHEIN_KEY_PWA_15391 } <a href="${ policyUrl }">${ SHEIN_KEY_PWA_15392 } </a>.`,
          2: SHEIN_KEY_PWA_15856,
          3: SHEIN_KEY_PWA_16113
        }
      }
    },
    // * 是否展示海路运标签
    showSeaOrLandTypeText(){
      if(this.product?.large_sea_and_land == 1 ){
        return this.language?.SHEIN_KEY_PWA_32176 || ''
      }
      if(this.product?.large_sea_and_land == 2 ){
        return this.language?.SHEIN_KEY_PWA_32175 || ''
      }
      return ''
    },
    showOpenSelfExchange () {
      return !!this.orderResultAbtInfo?.showOpenSelfExchange
    },
    paymentAmountTips () {
      // 如果商品行的是否重新分摊”字段值=是，则常态展示问号图标
      if (this.isReallocate) {
        return this.language.SHEIN_KEY_PWA_30869
      }
      const { avgPrice = null } = this.product
      const isShowAmount = this.isRetailPrice
      if (!isShowAmount) {
        return ''
      }
      const { 
        SHEIN_KEY_PWA_15428, 
        SHEIN_KEY_PWA_17217,
        SHEIN_KEY_PWA_17218, 
        SHEIN_KEY_PWA_17219,
      } = this.language
      // avgPrice 折后价格
      if (avgPrice) {
        return this.hideRetailPrice
          ? `${ SHEIN_KEY_PWA_15428 }`
          : `${ SHEIN_KEY_PWA_17219 }${ SHEIN_KEY_PWA_15428 }<br />${ SHEIN_KEY_PWA_17218 }${ SHEIN_KEY_PWA_17217 }`
      } else {
        return this.hideRetailPrice ? SHEIN_KEY_PWA_15428 : SHEIN_KEY_PWA_17217
      }
    },
    isRetailPrice() {
      const { avgPrice = null, product = {}, unitPrice = {} } = this.product
      const { amount } = product.retailPrice || {}
      if (avgPrice) {
        return +amount > +avgPrice.amount
      } else {
        return +amount > +unitPrice.amount
      }
    },
    hideRetailPrice() {
      return !!this.orderResultAbtInfo?.hideRetailPrice
    },
    quickShipAbt() {
      return this.orderResultAbtInfo?.quickShipAbt || {}
    },
    // 是否重新分摊
    isReallocate() {
      if (!Array.isArray(this.product.promotion_list)) return false
      return this.product.promotion_list.some((child) => {
        return child.recalc_appt_list?.some(type => type == 1)
      })
    },
    isPriceAmount() {
      const { avgPrice = null, unitPrice = {} } = this.product
      if (avgPrice) {
        return avgPrice.amountWithSymbol
      } else {
        return unitPrice.amountWithSymbol
      }
    },
    smallFont() {
      // 设计稿为9px字体，针对多语言较长的情况缩小字体，否则单个单词过长无法换行会超出容器
      return ['de', 'pl', 'pt-pt', 'ru'].includes(this.locals.appLanguage)
    }
  },
  mounted() {
    this.initExposeEvent()
  },
  methods: {
    ...mapMutations('orderDetail', ['assignState', 'changePageStatus', 'showMsg']),
    ...mapMutations('root', ['changeRootStatus']),
    ...mapMutations('cancelItemModule', ['assignCancelItemState']),
    // 币种amountWithSymbol的金额币种+？？？？（以4个？代替金额数字）
    replaceAmountWithSymbol(item = {}) {
      let amountWithSymbol = item.product.retailPrice.amountWithSymbol || ''
      if (this.blindBoxFlag) {
        let amountList = ['????']
        // 货币符号是否在右边
        const isSymbolRight = /^\d/.test(amountWithSymbol)
        // 获取货币符号
        const symbol = amountWithSymbol.replace(/[0-9,.]+/ig, '')
        if (symbol) {
          if (isSymbolRight) {
            amountList.push(symbol)
          } else {
            amountList.unshift(symbol)
          }
        }
        amountWithSymbol = amountList.join('')
      }
      return amountWithSymbol
    },
    getGoodsListStr(item, index) {
      let { goods_id = '', goods_sn = '' } = item?.product || {}
      let qsFlag = this.showQuickShipTag(item)
      return `${goods_id}\`${goods_sn}\`\`${index}\`1\`1\`\`\`\`${qsFlag ? 1 : ''}\`\``
    },
    getGoodsUrl(goods_url_name = '', goods_id = '') {
      let url =
        '/' +
        goods_url_name.replace('/', '-').split(' ').join('-') +
        '-p-' +
        goods_id +
        // '-cat-' +
        // cat_id +
        '.html'
      return replaceHrefSpecChar({ href: url })
    },
    transformImg,
    showQuickShipTag(item = {}) {
      return orderLogic?.showGoodsQuickShipTag(this.orderInfo, item, this.isShowQuickShipTag)
    },
    showFreeShipTag(item = {}) {
      return item?.free_freight_flag
    },
    showTip () {
      analysis.clickAmount()
      this.showAvgPriceTip = !this.showAvgPriceTip
    },
    cancelProductEvt(product) {
      analysis.click_cancel_inline({ ordergoodsid: product.id, goodsid: product.goods_id })
      if (this.orderInfo.payment_method == 'cod') {
        this.$emit('cancelGoodsEvt', product)
      } else {
        this.$emit('showRefundPage', product)
      }
    },
    exchangeProductEvt(product) {
      analysis.ClickExchange()
      analysis.click_exchange({ ordergoodsid: product.id, goodsid: product.goods_id })
      this.$emit('exchangeGoodsEvt', product)
    },
    async refundItem(id) {
      this.changeRootStatus({
        loading: true
      })
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getRefundGoodsInfo/query',
        data: {
          billno: this.orderInfo.billno,
          order_good_id_list: id,
          apply_scene: 1,
          payment_method: this.orderInfo.payment_method
        },
        headers: { blackbox: window._fmOpt?.__blackbox || '' }
      })
      this.changeRootStatus({
        loading: false
      })

      if (res.code == '0') {
        // 关联退弹框展示
        let resInfo = res?.info || {}
        let isAddOrderList = resInfo?.refund_goods?.filter(item => item.is_added_order == 1)
        let isRiskPunish = resInfo?.refund_goods?.some(item => item?.is_risk_punish === 1)

        if (
          resInfo.promotionBundleFlag > 0
          || isAddOrderList?.length
          || isRiskPunish
        ) {
          this.showRelationDialog = true
          this.relationGoodsList = resInfo?.refund_goods
          this.relationOptions = {
            ...this.relationOptions,
            billno: this.orderInfo.billno,
            cancelInfo: resInfo,
            pageFrom: 'detail',
            type: 1
          }
          this.exposeCombinedRefundTip('1-19-1-76')
        } else {
          this.emitRefundForm(resInfo)
        }
      } else {
        if (res.code == 666666 || res.code == 777777) {
          this.$toast(this.language.SHEIN_KEY_PWA_17115)
        } else {
          this.showMsg({
            msg: res?.tips || res?.msg || this.language.SHEIN_KEY_PWA_15409
          })
        }
      }
    },
    updateShowValue() {
      this.showRelationDialog = false
    },
    confirmCancelSubmit(cancelInfo = {}) {
      this.showRelationDialog = false
      this.emitRefundForm(cancelInfo)
    },
    exposeCombinedRefundTip(type, clickType = '') {
      let mapBillno = this.relationGoodsList?.map(item => item.billno)
      let mapGoodsId = this.relationGoodsList?.map(item => item.order_goods_id)
      let { billno } = this.relationOptions || {}
      let baseParams = {
        order_no: billno,
        relation_billno: mapBillno.join(','),
        relation_goods_id: mapGoodsId.join(',')
      }
      if (clickType) {
        baseParams = { ...baseParams, type: clickType }
      }
      daEventCenter.triggerNotice({
        daId: type,
        extraData: baseParams
      })
    },
    clickGoodsList(obj) {
      if (this.blindBoxFlag) return
      const { is_prime_goods, is_xtra_goods, product, totalPrice, unitPrice } = obj || {}
      const { unit_discount } = product || {}
      if (is_prime_goods || is_xtra_goods || (unitPrice.amount == totalPrice.amount && +totalPrice.amount == 0 && +unit_discount == 0)) return
      this.$router.push(this.locals.langPath + this.getGoodsUrl(product.goods_url_name, product.goods_id, product.cat_id))
    },
    async showP65Warning(goods_sn) {
      let p65Text = await chemicalsIdsEvt(this.orderInfo || {}, goods_sn)
      this.$parent.showP65Warning({
        text: p65Text || defaultP65TipsContent(this.language)
      })
    },
    /**
     * @description 初始化部分refundForm数据，用于refund-item页使用
     * @date 2021-09-27 17:28
     * @param {object} info
     */
    emitRefundForm(info) {
      const { orderCurrency } = info || {}
      let refundForm = {
        data: info,
        orderCurrency
      }
      // 更新refundForm
      this.assignCancelItemState({
        visibleMainDrawer: true,
        orderInfo: this.orderInfo,
        language: this.language,
        // 更新refundForm
        refundForm: { ...this.refundForm, ...refundForm },
        // 组件切换
        matchCompName: 'refund-item',
        compStacks: []
      })
    },
    initExposeEvent() {
      this.$nextTick(() => {
        daEventExpose.subscribe({
          keycode: 'orderDetailProductBox`1-8-5-18'
        })
      })
    },
    showFreeGiftTag(orderGoods = {}) {
      return orderLogic?.isFreeGiftItem(orderGoods)
    },
    handleShowSuccessPanel(data) {
      this.$emit('handleShowSuccessPanel', data)
    },
    isShowContinueWait (product) {
      if (typeof window === 'undefined') return false

      const { id } = product
      const cacheKey = 'soldoutContinueWait'
      try {
        const cache = JSON.parse(localStorage.getItem(cacheKey) || '{}')
        const item = cache[id]
        if (item) {
          this.chosenContinueWait = true
        }
      } catch (e) {
        console.error(e)
      }
      return this.orderResultAbtInfo.continueToWaitAbt && !this.chosenContinueWait
    },
    continueToWait(product) {
      const { id } = product
      const cacheKey = 'soldoutContinueWait'

      this.chosenContinueWait = true
      this.$toast(this.language.SHEIN_KEY_PWA_35706)

      daEventCenterV2.triggerNotice({
        id: 'click_contine_to_wait:simple',
        data: { order_goods_id: id }
      })

      const cache = JSON.parse(localStorage.getItem(cacheKey) || '{}')
      cache[id] = true
      localStorage.setItem(cacheKey, JSON.stringify(cache))
    }
  }
}
</script>
<style lang="less" scoped>
.mshe-no-border {
  width: 4rem;
  font-weight: 400;
}
.price-tips-ctn{
  color: #959595 !important; /* stylelint-disable-line declaration-no-important */
  .font-dpr(24px);
}
.extra-point-tip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ship-tag {
  margin-top: 8/75rem;
  display: inline-block;
  color: #198055;
  .font-dpr(20px);
  background: #f5fcfb;
  padding: 3/75rem 8/75rem;
}
.order-evolu, .goods-qs-tag {
  margin-top: 8/75rem;
  margin-right: 12/75rem;
}

.warning-tip {
  &.title-inner {
    margin-bottom: 0.08rem;
  }
  width: 1.707rem;
  background: rgba(255, 231, 231, 0.7);
  border: 1px solid rgba(255, 65, 28, 0.6);
  display: flex;
  align-items: center;
  .padding-l(0.053rem);
  & when(@IS_RW) {
    background: #fff6f4;
    border: 1px solid #e34e60;
  }
  text-align: center;
  font-size: 0;
  img {
    height: 0.267rem;
    width: auto;
    margin-right: 1px;
    vertical-align: sub;
  }
  span {
    display: inline-block;
    line-height: 0.4rem;
    text-transform: uppercase;
    font-size: 0.533rem;
    font-weight: bold;
    transform: scale(0.5);
    .transform-origin-l();
    color: #ff411c;
    & when(@IS_RW) {
      color: #e34e60;
    }
  }
}
.left-inner {
  position: relative;
  .free-tag {
    position: absolute;
    width: 144/75rem;
    height: 144/75rem;
    bottom: 0;
    border-radius: 50%;
    background: #ff7f46;
    border: 8/75rem solid #feecc3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 36/75rem;
    color: #fff;
    line-height: 1.2;
    transform: scale(0.5);
    transform-origin: left bottom;
    z-index: 9;
    & > p.small-font {
      font-size: 0.8em;
    }
    &.special-free__tag{
      background: #c96e3f;
    }
  }
}
</style>
