<template>
  <div class="order-track-info">
    <logistics-steps
      v-if="!!trackInfoLength && !locals.IS_RW"
      :track-info="trackInfo"
      :language="language"
      :order-status-text="orderStatusText"
      :is-processing="isProcessing"
    />
    <template v-for="(item, index) in orderInfo.trackList">
      <div
        v-if="analysisPackages.includes(item.package_no)"
        :key="index"
        class="order-track-info__expose"
      ></div>
    </template>
    <order-detail-track
      v-if="!!trackInfoLength"
      :track-info="trackInfo"
      :language="language"
      :is-processing="isProcessing"
      :order-status-text="orderStatusText"
    />
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive.js'
import LogisticsSteps from './LogisticsSteps.vue'
import OrderDetailTrack from './OrderDetailTrack.vue'
import { mapState } from 'vuex'
import orderLogic from 'public/src/pages/common/orderLogic'

export default {
  name: 'OrderDetailTrackInfo',
  directives: { expose },
  components: {
    LogisticsSteps,
    OrderDetailTrack
  },
  props: {
    tabSelected: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'locals',
      'orderInfo',
      'language',
      'pageLoading',
      'orderResultAbtInfo',
      'orderStatusTextList'
    ]),
    showTabItem() {
      return (
        !this.pageLoading &&
        this.orderInfo?._showOrderDetailTab == 1 &&
        Object.keys(this.orderInfo?._allOrderGoodsList).length > 1
      )
    },
    isProcessing() {
      const { processingTabIndex, _isShppedType, order_package_info_list = [], _allOrderGoodsList = [] } = this.orderInfo
      if (this.showTabItem) {
        return this.tabSelected == processingTabIndex
          || _allOrderGoodsList?.[this.tabSelected]?.some(item => item.orderGoodsStauts == 2)
      }
      return _isShppedType == 1
        || _isShppedType == 3
        || [11, 13, 23, 49].includes(order_package_info_list?.[0]?.status)
        || _allOrderGoodsList[0].some(item => item.orderGoodsStauts == 2)
    },
    orderStatusText() {
      if (!this.orderResultAbtInfo?.isCanRefundAbt?.includes?.('BBC=on')) return ''
      let { orderGoodsList = {}, orderTrackInfo = {} } = this.orderInfo || {}
      let packStatus = ''
      const packageNo = orderTrackInfo?.package_no || ''
      if (this.isProcessing) {
        packStatus = orderGoodsList?.flat(Infinity)?.find(item => item?.reference_number == packageNo)?.pack_detail_status
      } else {
        packStatus = orderGoodsList?.flat(Infinity)?.find(item => item?.pack_detail_status == '0' || !!item?.pack_detail_status)?.pack_detail_status
      }
      let cccText = ''

      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.orderInfo,
        order_or_package_status: packStatus
      })
      cccText = matchObj?.statusText

      return cccText || ''
    },
    trackInfo () {
      return this.orderInfo?.orderTrackInfo || {}
    },
    trackInfoLength () {
      return Object.keys(this.trackInfo)?.length
    },
    analysisPackages () {
      if (this.trackInfoLength) {
        return [this.trackInfo?.package_no]
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="less">
.order-track-info {
  position: relative;

  &__expose {
    position: absolute;
    height: 1px;
    width: 1px;
    opacity: 0;
    pointer-events: none;
  }
}
</style>
